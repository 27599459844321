export default function ajax(options){
  var xhr = null;
  //创建对象
  if(window.XMLHttpRequest){
    xhr = new XMLHttpRequest()
  } else {
    xhr = new ActiveXObject("Microsoft.XMLHTTP");
  }
  // 连接
  if(options.type == "GET"){
    var params = formsParams(options.data);
    xhr.open(options.type,options.url + "?"+ params,options.async);
    xhr.send(null)
  } else if(options.type == "POST"){
    xhr.open(options.type,options.url,options.async);
    xhr.responseType = 'arraybuffer'; //一定要设置响应类型，否则页面会是空白pdf
    var params = JSON.stringify(options.data);
    xhr.setRequestHeader("Content-type", "application/json;charset=UTF-8"); //可以发送json格式字符串
    xhr.send(params);
  }
  xhr.onreadystatechange = function(){
    if(xhr.readyState==4){
      if(xhr.status == 200 || xhr.status == 201 || xhr.status == 400){
        if(xhr.status==400){
          options.success({
            errorMsg:"错误原因:文件不存在"
          });
        }else{
          options.success(xhr.response);
        }

      }else{
        options.error(xhr.response);
      }
    }
  }
  function formsParams(data){
    var arr = [];
    for(var prop in data){
      arr.push(prop + "=" + data[prop]);
    }
    return arr.join("&");
  }

}

<template>
  <div class="tab-module">
    <el-tabs :value="tabs_active" @tab-click="tabClickFun">
      <template v-for="(item) in tabs_data">
        <!-- <el-tab-pane :key="item.name" :label="item.title" :name="item.name"
          v-if="item.name == 4 ? isShow_som_account : true">
        </el-tab-pane> -->
        <el-tab-pane :key="item.name" :label="item.title" :name="item.name">
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
  export default {
    // 允许组件模板递归地调用自身
    name: 'tabs-temp',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      type: { // 组件类型 link/链接跳转 tabs/标签切换
        type: String,
        default: 'link'
      },
      current: { // 组件当前下标
        type: [Number, String],
        default: '1'
      },
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        isShow_som_account: true, // 是否显示子账号选项
        tabs_active: this.current,
        tabs_data: [{
            title: '保险首页',
            name: '1',
            url: '/insurance'
          }, {
            title: '订单查询',
            name: '2',
            url: '/insurance?id=2'
          }, {
            title: '理赔',
            name: '3',
            url: '/insurance?id=3',
          },
          // {
          //   title: '子账号管理',
          //   name: '4',
          //   url: '/insurance?id=4',
          //   // show: false,
          // },
          {
            title: '钱包',
            name: '4',
            url: '/insurance?id=4',
          }
        ],
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      type: function (e) {
        // 初始化 tab
        this.initTabFun(e);
      },
      current: function (e) {
        console.log('watch current e == ', e);
        if (e) {
          e = String(e);
          this.tabs_active = e;
        }
      },
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取账号信息
        // this.getUserInfoFun();
        // 初始化 tab
        this.initTabFun();
      },
      /**
       * 初始化 tab
       */
      initTabFun(e) {
        e = e || this.type;
        if (e == 'link') {
          this.tabs_active = '';
          return;
        }
        this.tabs_active = String(this.current);
        let session = this.myGetSession(this.$options.name);
        console.log('session == ', session);
        console.log('this.$options.name == ', this.$options.name);
        if (session) {
          this.tabs_active = String(session);
        }
        console.log('this.tabs_active == ', this.tabs_active);
      },
      /**
       * tab 点击
       */
      tabClickFun(e, url) {
        console.log('e == ', e)
        console.log('e.name == ', e.name)
        let {
          name
        } = e;
        // 事件决策
        this.eventDecisionFun(name)
      },
      /**
       * 设置当前页面缓存
       */
      setAtPageSessionFun(val) {
        this.mySetSession(this.$options.name, val)
      },
      /**
       * 移除当前页面缓存
       */
      removeAtPageSessionFun() {
        this.myRemoveSession(this.$options.name)
      },
      /**
       * 事件决策
       */
      eventDecisionFun(name) {
        name = String(name);
        let {
          type
        } = this;
        console.log('事件决策 type == ', type)

        switch (type) {
          case 'link':
            this.executeLinkFun(name);
            break;
          case 'tabs':
            this.executeTabsFun(name);
            break;
        }
      },
      /**
       * 执行跳转
       */
      executeLinkFun(name) {
        let {
          tabs_data
        } = this;
        let items = tabs_data.find(item => item.name == name);
        console.log('items == ', items)
        this.$router.push(items.url);
      },
      /**
       * 执行 tabs
       */
      executeTabsFun(name) {
        this.tabs_active = name;
        // // 设置当前页面缓存
        // this.setAtPageSessionFun(name);

        // 告知弹窗变化
        this.informDialogChangeFun({
          data: name
        });
      },
      /**
       * 告知弹窗变化
       */
      informDialogChangeFun(opt) {
        let default_data = {
          visible: false,
          status: 1, // 1/成功 2/取消 3/取消，关闭弹窗
          message: '成功',
          data: '',
        };
        let result = Object.assign(default_data, opt);
        this.$emit('on-change', result)
      },
      /**
       * 获取 tabs 当前数据对象
       */
      getTabsNameFun(callback) {
        let {
          tabs_active,
          tabs_data
        } = this;
        let data = tabs_data.find(item => item.name == tabs_active);
        let result = {};
        if (data) result = data;
        if (callback) {
          callback(result);
        }
        return result;
      },
      /**
       * 获取账号信息
       */
      getUserInfoFun() {
        this.myRequest({
          url: '/api/user/userinfostatus',
        }).then((res) => {
          console.log('获取账号信息 res == ', res)
          let resData = res.data.data;
          let {
            son_account
          } = resData;
          let flag = son_account > 0;
          console.log('获取账号信息 flag == ', flag)
          this.isShow_som_account = flag; // 子账号是否显示
          // this.isShow_som_account = true; //flag; // 子账号是否显示
          console.log('获取账号信息 this.isShow_som_account == ', this.isShow_som_account)
        })
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前
      // 移除当前页面缓存
      this.removeAtPageSessionFun();
    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .tab-module {
    box-sizing: border-box;

    &::v-deep {
      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__nav-wrap::after {
        display: none;
      }

      .el-tabs__item {
        $height: 5px;
        // padding-top:  $height;
        // padding-bottom:  $height;
        height: auto;
        font-size: 18px;
        color: #444343;

        &.is-active {
          color: #D42A20;
        }
      }

      .el-tabs__active-bar {
        background: #D42A20;
      }
    }
  }

</style>

<template>
  <div class="container">
    <div class="block-module">
      <div class="tab-header">
        <tabs-tmpe />
      </div>
    </div>
    <div class="place">
      <div class="pay-box pay-info">
        <div class="rest-box">
          <div class="pay-status">
            <i class="iconfont icon-chenggong" v-if="page_options.parent_pay_status.pay_status == 1"></i>
            <i class="iconfont icon-shibai" v-else></i>
          </div>
        </div>
        <div class="main-box">
          <h3 class="info-title">
            <span
              v-if="page_options.parent_pay_status.pay_status == 1">{{page_options.parent_pay_status.pay_status_msg}}！</span>
            <span v-if="orderData.pay_status == 1 || orderData.pay_status == 2">{{orderData.pay_status_msg}}</span>
            <span v-else>保单正在生成，请稍等{{page_options.ellipsis_active}}</span>
          </h3>
          <p class="info-descriptions">如对此订单有疑问您可以拨打客服电话进行咨询，电话：0775-23764513&nbsp;&nbsp;13430414113</p>
          <p class="info-descriptions">服务时间：周一至周日（09:00-22:00）</p>
        </div>
      </div>
      <div class="pay-box pay-control">
        <div class="rest-box"></div>
        <div class="main-box">
          <ul class="control-list">
            <li class="cl-item">
              <el-button class="fake-btn" @click="lookingOrderFun">
                <span>查看订单</span>
              </el-button>
            </li>
            <!-- <li class="cl-item" v-if="orderData.pay_status == 1">
              <el-button class="fake-btn" @click="downloadInsuranceFun">
                <span>下载保单</span>
              </el-button>
            </li> -->
            <li class="cl-item">
              <el-button class="fake-btn" @click="goToHomePage">
                <span>返回首页</span>
              </el-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ajax from '@/utils/my_ajax.js';

  import {
    deepClone, // 深拷贝
  } from '@/utils/utils_fun.js';
  import tabsTmpe from '@/views/insurance/components/tabs-temp/tabs-temp.vue';
  export default {
    // 允许组件模板递归地调用自身
    name: '',
    // 声明一组可用于组件实例中的组件
    components: {
      tabsTmpe,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        current_timer: null, // 当前定时器
        page_options: { // 页面信息参数
          order_id: '',
          parent_pay_status: '', // 前一次支付状态
          ellipsis_active: '...', // 省略号
          ellipsis_timer: null, // 省略号
        },
        orderData: {}, // 订单信息
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取路由信息
        this.getRouterInfoFun().then(res => {
          console.log('获取路由信息 res == ', res)
          let resData = JSON.parse(decodeURIComponent(res.query.data));
          console.log('resData == ', resData)
          let {
            order_id,
            order_type
          } = resData;

          this.page_options = Object.assign(this.page_options, resData);
          console.log('this.page_options == ', this.page_options)
          // 省略号动画
          this.ellipsisAnimationFun();
          // 获取订单信息
          this.getOrderInfoFun(order_id, order_type);
        })
      },
      /**
       * 省略号动画
       */
      ellipsisAnimationFun() {
        const _this = this;
        this.page_options.ellipsis_timer = setInterval(() => {
          let {
            pay_result_status
          } = _this.orderData;
          if (pay_result_status == 1) {
            clearInterval(anima);
          }
          let {
            ellipsis_active
          } = _this.page_options;
          let ellipsis_length = ellipsis_active.length;
          if (ellipsis_length >= 3) {
            _this.page_options.ellipsis_active = '..';
          } else if (ellipsis_length == 2) {
            _this.page_options.ellipsis_active = '.';
          } else if (ellipsis_length <= 1) {
            _this.page_options.ellipsis_active = '...';
          }
        }, 500);
      },
      /**
       * 获取订单信息
       */
      getOrderInfoFun(order_id, order_type) {
        const _this = this;
        // this.myRequest({
        //   url: '/api/order/orderpaystatus',
        //   data: {
        //     id: order_id,
        //     type: order_type,
        //   }
        // }).then(res => {
        //   console.log('获取订单信息 res == ',res);
        //   this.orderData = res.data.data;
        // })

        // 循环请求
        this.myLoopRequest({
          id: order_id,
          type: order_type
        }, function (resObj) {
          console.log('myLoopRequest == ', resObj)
          let res = deepClone(resObj);
          let {
            timer,
            data
          } = res;

          // 获取定时器
          if (!_this.current_timer) {
            _this.current_timer = timer;
          }

          // console.log('data.pay_result_status == ',data.pay_result_status)
          // 是否已支付
          if (data.pay_result_status == 1) {
            console.log('是否已出单 == ', data.pay_result_status)
            console.log('获取订单信息 res == ', res);
            _this.orderData = data;
            _this.page_options.parent_pay_status.pay_status = data.pay_status;
            // 结束函数
            _this.endEventFun(data);
          }
        });
      },
      /**
       * 结束函数
       */
      endEventFun() {
        // 自定义清除定时器
        this._clearIntervalFun();
      },
      /**
       * 自定义清除定时器
       */
      _clearIntervalFun() {
        let {
          current_timer
        } = this;
        let {
          ellipsis_timer
        } = this.page_options;
        if (this.current_timer) {
          clearInterval(current_timer);
          current_timer = null;
        }
        if (ellipsis_timer) {
          clearInterval(ellipsis_timer);
          ellipsis_timer = null;
        }
      },
      /**
       * 跳转首页
       */
      goToHomePage() {
        this.myRouterPush({
          path: '/insurance?id=1',
        })
      },
      /**
       * 保单下载
       */
      downloadInsuranceFun() {
        var self = this;
        let {
          trade_no
        } = this.orderData;
        console.log('保单下载 this.orderData == ', this.orderData)
        console.log('保单下载 trade_no == ', trade_no)
        if (!trade_no) {
          self.myMessage({
            message: '保单不存在'
          });
          return;
        }
        ajax({
          url: this.GLOBAL.insurance.requestConfig.standbyUrl + "/downorderploxyfile/" + trade_no,
          type: "POST", // type ---> 请求方式
          async: true, // async----> 同步：false，异步：true
          data: { //传入信息
            "loginname": this.GLOBAL.insurance.accountConfig.account,
            "loginpwd": this.GLOBAL.insurance.accountConfig.passwrod,
            "filetype": 0,
          },
          success: function (data) { //返回接受信息
            if (data.errorMsg) {
              self.myMessage({
                message: data.errorMsg
              });
            } else {
              var binaryData = [];
              binaryData.push(data);
              //获取blob链接
              var pdfUrl = window.URL.createObjectURL(new Blob(binaryData, {
                type: 'application/pdf'
              }));
              // window.open(pdfUrl,'_blank');
              // 下载并保存文件
              self.myDownloadFile(pdfUrl);
              // self.thirdDownloadFile(pdfUrl);
            }
          },
          error: function (data) {
            self.myMessage({
              message: '网络错误!'
            });
          }
        })
      },
      /**
       * 获取路由信息
       */
      getRouterInfoFun() {
        return this.myGetRouter()
        // .then(res => {
        //   console.log('获取路由信息 res == ',res)
        //   // let { order_id } = res.query;
        //   this.orderData = res.query
        //   console.log('获取路由信息 res == ',res)
        //   resolve(res.query);
        // })
      },
      /**
       * 查看订单
       */
      lookingOrderFun() {
        let {
          order_id
        } = this.page_options;
        this.myRouterPush({
          path: `/insurance/query/index?id=${order_id}`,
        })
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前
      // 自定义清除定时器
      this._clearIntervalFun();
    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .block-module {
    background: #fff;
    border-bottom: 24px solid #F5F5F5;

    &.not-border-bottom {
      border-bottom: none;
    }
  }

  .tab-header {
    box-sizing: border-box;
    padding: 10px $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }

  .container {
    box-sizing: border-box;
    min-height: 100vh;
    background: #f5f5f5;

    .place {
      box-sizing: border-box;
      padding: 0 $theme-widht-whiteedge;
      // width: $theme-view-widht;
      max-width: $theme-view-widht;
      min-width: $theme-view-min-widht;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 40px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0 0 14px -21px rgba(0, 0, 0, .6);

      .pay-box {
        display: flex;

        .rest-box {
          width: 42px;
          margin-right: 20px;

          .pay-status {
            height: 42px;

            .iconfont {
              font-size: 42px;
              color: #E62129;
            }
          }
        }

        .main-box {
          flex: 1;
          min-width: 0;
        }

        &.pay-info {
          box-sizing: border-box;
          padding-bottom: 35px;
          margin-bottom: 35px;
          border-bottom: 1px solid #CCCCCC;

          .info-title {
            margin-top: 6px;
            font-weight: 700;
            font-size: 22px;
            color: #444343;
          }

          .info-descriptions {
            margin-top: 5px;
            font-size: 14px;
            color: #444343;

            &:first-of-type {
              margin-top: 16px;
            }
          }

          .main-box {}
        }

        &.pay-control {
          .control-list {
            display: flex;

            .cl-item {
              margin-left: 30px;

              &::v-deep {
                .fake-btn {
                  width: 100%;
                  height: 36px;
                  padding: 0 15px;
                  font-size: 16px;
                  color: #787878;
                  border: 1px solid #CCCCCC;
                  background: #fff;
                  outline: none;

                  &.el-button:hover,
                  &.el-button:focus,
                  &.el-button:active {
                    color: none;
                    border-color: none;
                    background-color: none;
                  }

                  &.el-button:active {
                    color: $active-default-color;
                    border-color: $active-default-color;
                    background-color: #fff;
                  }
                }
              }

              &:first-of-type {
                margin-left: 0;

                &::v-deep {
                  .fake-btn {
                    color: #fff;
                    border: 1px solid #E62129;
                    background: #E62129;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

</style>

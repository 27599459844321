import { render, staticRenderFns } from "./tabs-temp.vue?vue&type=template&id=7882d7bc&scoped=true&"
import script from "./tabs-temp.vue?vue&type=script&lang=js&"
export * from "./tabs-temp.vue?vue&type=script&lang=js&"
import style0 from "./tabs-temp.vue?vue&type=style&index=0&id=7882d7bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7882d7bc",
  null
  
)

export default component.exports